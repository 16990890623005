// 再表示しないためのチェックボックスつき。内容はModal2とほぼ同様

import React from "react"
import PropTypes from "prop-types"

class SharedModal3 extends React.Component {
  setCookie(e) {
    const target = e.currentTarget
    console.log(target.checked)
    if (target.checked) {
      document.cookie = "tw_check=on"
    } else {
      document.cookie = "tw_check=off"
    }
  }

  render() {
    const openStyle = this.props.openStatus ? { opacity: 1, visibility: 'visible', top: `calc(-100vh + 60px)` } : { opacity: 0, visibility: 'hidden', top: `calc(-100vh + 60px)` }

    return (
      <div className="confirm-modal" style={openStyle}>
        <div className="overlay" onClick={this.props.closeFunc.bind(this)}></div>
        <div className="modal">
          <h2 className="ja-16 bold text-high" dangerouslySetInnerHTML={{ __html: this.props.title }}></h2>
          {this.props.text ? <p className="mt8 ja-14 text-high" dangerouslySetInnerHTML={{ __html: this.props.text }}></p> : ''}

          <label htmlFor="dont-show" className="dsp-block mt14 check-label ja-14 text-high">
            <span className="checkbox-wrapper">
              <input type="checkbox" id="dont-show" onChange={this.setCookie.bind(this)} />
              <span className="checkbox-view"></span>
              以降表示しない
            </span>
          </label>

          <div className="confirm-buttons">
            <button className="default-btn ja-14 medium hov-op_8" onClick={this.props.upButtonFunc.bind(this)}>{this.props.upButtonLabel}</button>
            <button className="white-btn ja-14 medium hov-op_8" onClick={this.props.bottomButtonFunc.bind(this)}>{this.props.bottomButtonLabel}</button>
          </div>
        </div>
      </div>
    );
  }
}
SharedModal3.propTypes = {
  openStatus: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  upButtonLabel: PropTypes.string,
  bottomButtonLabel: PropTypes.string,
  upButtonFunc: PropTypes.func,
  bottomButtonFunc: PropTypes.func,
  closeFunc: PropTypes.func,
};
export default SharedModal3
