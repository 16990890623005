import React from "react"

class UsersPassewordsEdit extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    passwordError: false,
    passwordIsShow: false,
  }

  showPassword() {
    this.setState({ passwordIsShow: !this.state.passwordIsShow })
  }

  ZenkakuToHanksku(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}

  changPassword(e) {
    const target = e.currentTarget
    this.setState(({ password: this.ZenkakuToHanksku(target.value) }), () => {
      this.passwordValid()
    })
  }

  changConfirmPassword(e) {
    const target = e.currentTarget
    this.setState(({ confirmPassword: this.ZenkakuToHanksku(target.value) }), () => {
      this.passwordValid()
    })
  }

  passwordValid() {
    if (this.state.password === this.state.confirmPassword && this.state.password.length >= 8) {
      this.setState({ passwordError: false })
      return true
    } else {
      this.setState({ passwordError: true })
      return false
    }
  }

  componentDidMount() {
    this.passwordValid()
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-area">
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">新しいパスワード</label>
            <div className="input-wrap">
              <input
                type={this.state.passwordIsShow ? 'text' : 'password'}
                autoFocus="autofocus"
                autoComplete="new-password"
                className="form-input ja-14 password"
                id="user_password"
                onChange={this.changPassword.bind(this)}
                value={this.state.password}
                name="user[password]"
              />
              <span className={`password-eye ${this.state.passwordIsShow ? 'open' : 'closed'}`} onClick={this.showPassword.bind(this)}></span>
            </div>
            <p className="form-ex-txt-bottom text-medium ja-12">8文字以上・英数字</p>
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">新しいパスワードを再入力</label>
            <div className="input-wrap">
              <input
                type={this.state.passwordIsShow ? 'text' : 'password'}
                autoFocus="autofocus"
                autoComplete="new-password"
                className="form-input ja-14 password"
                id="user_password_confirmation"
                onChange={this.changConfirmPassword.bind(this)}
                value={this.state.confirmPassword}
                name="user[password_confirmation]"
              />
              <span className={`password-eye ${this.state.passwordIsShow ? 'open' : 'closed'}`} onClick={this.showPassword.bind(this)}></span>
            </div>
            <p className="form-ex-txt-bottom text-medium ja-12">8文字以上・英数字</p>
          </div>
        </div>

        <div className="form-button-area mt24">
          <button className="default-btn hov-op_8 ja-14 medium" disabled={this.state.passwordError}>{this.state.passwordError ? 'パスワードを入力してください' : '設定する'}</button>
        </div>
      </React.Fragment>
    );
  }
}

export default UsersPassewordsEdit
