import React from "react"
import PropTypes from "prop-types"

class UsersTelAuthShow extends React.Component {
  state = {
    authCode1: null,
    authCode2: null,
    authCode3: null,
    authCode4: null,
    authCode: null,
  }

  changeTel1(e) {
    let value = e.currentTarget.value.replace(/[A-Z a-z]/g, '')
    const value1 = value.charAt(0)
    const value2 = value.charAt(1)
    const value3 = value.charAt(2)
    const value4 = value.charAt(3)
    this.setState(({
      authCode1: value1,
      authCode2: value2,
      authCode3: value3,
      authCode4: value4,
    }), () => {
      this.setAuthCode()
    })
  }

  changeTel2(e) {
    let value = e.currentTarget.value
    const value2 = value.charAt(0)
    const value3 = value.charAt(1)
    const value4 = value.charAt(2)

    this.setState(({
      authCode2: value2,
      authCode3: value3,
      authCode4: value4
    }), () => {
      this.setAuthCode()
    })
  }

  changeTel3(e) {
    let value = e.currentTarget.value
    const value3 = value.charAt(0)
    const value4 = value.charAt(1)

    this.setState(({
      authCode3: value3,
      authCode4: value4,
    }), () => {
      this.setAuthCode()
    })
  }

  changeTel4(e) {
    let value = e.currentTarget.value
    value = value.charAt(0)

    this.setState(({ authCode4: value }), () => {
      this.setAuthCode()
    })
  }

  setAuthCode() {
    const authCode = this.state.authCode1 + this.state.authCode2 + this.state.authCode3 + this.state.authCode4
    this.setState(({ authCode: authCode }), () => {
      this.focus()
    })
  }

  focus() {
    const length = this.state.authCode.length
    if (length >= 4) return

    const input = document.querySelector(`#code${length + 1}`)
    input.focus()
  }

  render() {
    return (
      <React.Fragment>
        <div className={`mt24 tel-auth-area ${this.props.alert && !this.state.authCode ? 'error' : ''}`}>
          <input id="tel_auth_code" required="required" autoComplete="off" type="hidden" name="tel_auth_code" value={this.state.authCode} />

          <input type="number" className="tel-input" onChange={this.changeTel1.bind(this)} value={this.state.authCode1} id="code1" />
          <input type="number" className="tel-input" onChange={this.changeTel2.bind(this)} disabled={!this.state.authCode || this.state.authCode.length < 1} value={this.state.authCode2} id="code2" />
          <input type="number" className="tel-input" onChange={this.changeTel3.bind(this)} disabled={!this.state.authCode || this.state.authCode.length < 2} value={this.state.authCode3} id="code3" />
          <input type="number" className="tel-input" onChange={this.changeTel4.bind(this)} disabled={!this.state.authCode || this.state.authCode.length < 3} value={this.state.authCode4} id="code4" />
        </div>
        {this.props.alert && !this.state.authCode ? <p className="auth-error mt12 ja-12 color-error">{this.props.alert}</p> : ''}

        <div className="form-button-area mt32">
          <button type="submit" className="default-btn hov-op_8 ja-14 medium" disabled={!this.state.authCode || this.state.authCode.length !== 4}>認証する</button>
          <a className="mt16 hov-op_8 button-area-link color-primary text-center ja-14 medium" rel="nofollow" data-method="post" href="/users/tel_auth/resend">コードを再送信する</a>
        </div>
      </React.Fragment>
    );
  }
}
UsersTelAuthShow.propTypes = {
  alert: PropTypes.string,
};
export default UsersTelAuthShow
