import React from "react"

class PaymentDoneModal extends React.Component {
  state = {
    openStatus: true,
  }
  closeModal() {
    this.setState({ openStatus: false })
  }

  render() {
    const openStyle = this.state.openStatus ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }

    return (
      <div className="payment-modal" style={openStyle}>
        <div className="overlay" onClick={this.closeModal.bind(this)}></div>
        <div className="modal">
          <div className="check"><img src="https://fety-assets.s3.ap-northeast-1.amazonaws.com//img/icon/payment_check.svg" alt="" /></div>
          <h2 className="ja-16 bold text-high text-center">プランのお申し込み<br />ありがとうございます！</h2>
          <p className="mt8 ja-14 text-high text-center">fetyをより便利にご利用<br />いただけるようになりました。<br />いいお相手との出会いが<br />ありますように！</p>

          <button className="mt8 default-btn ja-14 medium hov-op_8" onClick={this.closeModal.bind(this)}>OK</button>
        </div>
      </div>
    );
  }
}
export default PaymentDoneModal
