import React from "react"
import PropTypes from "prop-types"
import obtainUnicode from "obtain-unicode"
import compressor from "compressorjs"

import Modal1 from "../../shared/modal1"

class SettingsProfileShow extends React.Component {
  state = {
    nickname: this.props.nickname,
    age: this.props.age,
    height: this.props.height,
    weight: this.props.weight,
    twitterId: this.props.twitterId,
    comment: this.props.comment,
    position: this.props.position ? this.props.positions.map(pos => pos[1] === this.props.position ? pos[0] : null ).filter(Boolean) : '',
    bodyShape: this.props.bodyShape ? this.props.bodyShapes.map(pos => pos[1] === this.props.bodyShape ? pos[0] : null ).filter(Boolean) : '',
    userTimings: this.props.userTimings,
    activeAreas: this.props.userActiveAreas,
    userAppearances: this.props.userAppearances,
    imageUri: this.props.imageUri,
    base64Image: '',

    openActiveAreaModal: false,
    openAppearancesModal: false,
    openPosition: false,
    openBodyShape: false,
  }

  changeNickname(e) {
    const target = e.currentTarget
    this.setState(({ nickname: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeAge(e) {
    const target = e.currentTarget
    this.setState(({ age: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeHeight(e) {
    const target = e.currentTarget
    this.setState(({ height: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeWeight(e) {
    const target = e.currentTarget
    this.setState(({ weight: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeTwitterId(e) {
    const target = e.currentTarget
    this.setState(({ twitterId: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeComment(e) {
    const target = e.currentTarget
    let comment = target.value
    if (obtainUnicode.obtainUnicode(comment).length > 200) {
      const unicodeComment = obtainUnicode.obtainUnicode(comment).slice(0, 200)
      comment = String.fromCodePoint(...unicodeComment)
    }

    this.setState(({ comment: comment }), () => {
      this.unLockCheck()
    })
  }

  changePosition(e) {
    const target = e.currentTarget
    const position = this.props.positions.map(pos => pos[1] === target.value ? pos[0] : null ).filter(Boolean)
    this.setState(({ position: position }), () => {
      this.closePositionSelector()
      this.unLockCheck()
    })
  }

  changeBodyShape(e) {
    const target = e.currentTarget
    const bodyShape = this.props.bodyShapes.map(pos => pos[1] === target.value ? pos[0] : null ).filter(Boolean)
    this.setState(({ bodyShape: bodyShape }), () => {
      this.closeBodyShapeSelector()
      this.unLockCheck()
    })
  }

  // Timing
  changeTiming(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userTimings
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 1) {
      target.checked = false
      alert('都合のつきやすいタイミングは1個以内に設定してください')
    } else {
      this.setState(({ userTimings: newArray }), () => {
        this.unLockCheck()
      })
    }

  }

  // ActiveArea
  changeActiveArea(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.activeAreas
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 8) {
      target.checked = false
      alert('活動範囲は8個以内に設定してください')
    } else {
      this.setState(({ activeAreas: newArray }), () => {
        this.unLockCheck()
      })
    }
  }
  clearActiveArea(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ activeAreas: [] }), () => {
      this.unLockCheck()
    })
  }

  // Appearance
  changeAppearance(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userAppearances
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 3) {
      target.checked = false
      alert('見た目の特徴は3個以内に設定してください')
    } else {
      this.setState(({ userAppearances: newArray }), () => {
        this.unLockCheck()
      })
    }
  }

  clearAppearance(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ userAppearances: [] }), () => {
      this.unLockCheck()
    })
  }

  // Modal関係
  openActiveAreaModal() {
    this.setState({ openActiveAreaModal: true })
    this.closeBodyShapeSelector()
    this.closePositionSelector()
  }
  closeActiveAreaModal() {
    this.setState({ openActiveAreaModal: false })
  }

  openAppearancesModal() {
    this.setState({ openAppearancesModal: true })
    this.closeBodyShapeSelector()
    this.closePositionSelector()
  }
  closeAppearancesModal() {
    this.setState({ openAppearancesModal: false })
  }

  // Selector関係
  togglePositionSelector() {
    this.setState({ openPosition: !this.state.openPosition })
    this.closeBodyShapeSelector()
  }
  openPositionSelector() {
    this.setState({ openPosition: true })
    this.closeBodyShapeSelector()
  }
  closePositionSelector() {
    this.setState({ openPosition: false })
  }

  toggleBodyShapeSelector() {
    this.setState({ openBodyShape: !this.state.openBodyShape })
    this.closePositionSelector()
  }
  openBodyShapeSelector() {
    this.setState({ openBodyShape: true })
    this.closePositionSelector()
  }
  closeBodyShapeSelector() {
    this.setState({ openBodyShape: false })
  }

  // image
  setImage(imageUri, base64Image) {
    this.setState(({
      imageUri: imageUri,
      base64Image: base64Image
    }), () => {
      console.log(this.state.imageUri)
    })
  }
  async changeImage(e) {
    const files = e.target.files
    if (!files) return

    const file = files[0]
    if (typeof file !== 'object') return

    let base64Image = ''
    let blobUri = ''
    const parentThis = this

    new compressor(file, {
      quality: 0.3,
      success(result) {
        blobUri = window.URL.createObjectURL(result) // Blob URL Scheme を生成

        let reader = new FileReader()
        reader.readAsDataURL(result) // blob を base64 へ変換し onload を呼び出す

        reader.onload = () => {
          base64Image = reader.result
          parentThis.setImage(blobUri, base64Image)
        }
      },
      maxWidth: 800,
      maxHeight: 800,
      mimeType: 'image/jpg',
      error(err) {
        console.log(err)
      },
    })
  }

  unLockCheck() {
    if (this.state.age && this.state.height && this.state.weight && this.state.twitterId && this.state.userTimings.length > 0 && this.state.userAppearances.length > 0 && this.state.activeAreas.length > 0 && this.state.position && this.state.bodyShape) {
      const submitButton = document.querySelector('#submitButton')
      submitButton.disabled = false
      submitButton.textContent = '更新する'
    } else {
      const submitButton = document.querySelector('#submitButton')
      submitButton.disabled = true
      submitButton.textContent = 'すべて入力してください'
    }
  }

  componentDidMount() {
    this.unLockCheck()
  }

  render() {
    const timings = this.props.timings.map((timing, key) => <label htmlFor={timing.public_id} className="check-label ja-14 text-high" key={key}>
      <span className="checkbox-wrapper">
        <input
          type="checkbox"
          id={timing.public_id}
          name={`timings[${timing.public_id}]`}
          defaultChecked={this.props.userTimings.map(tm => tm.public_id).includes(timing.public_id)}
          onChange={this.changeTiming.bind(this)}
        />
        <span className="checkbox-view"></span>
      </span>{timing.name}
    </label>)

    const activeAreas = this.props.activeAreaCategories.map((activeAreaCategory, key) => <div className="check-wrapper" key={key}>
      <h3 className="ttl ja-14 text-medium medium">{activeAreaCategory.name}</h3>
      <div className="checks mt8">
        {activeAreaCategory.active_areas.map((area, areakey) =>
          <label className="check-label ja-14 text-high" htmlFor={area.public_id} key={areakey}>
            <span className="checkbox-wrapper text-high ja-14">
              <input type="checkbox"
                id={area.public_id}
                name={`active_areas[${area.public_id}]`}
                defaultChecked={this.props.userActiveAreas.map(area => area.public_id).includes(area.public_id)}
                onChange={this.changeActiveArea.bind(this)}
                data-name={area.name}
              />
              <span className="checkbox-view"></span>
            </span>{area.name}
          </label>)}
      </div>
    </div>)

    const appearances = this.props.appearances.map((appearance, key) => <div className="checks mt8" key={key}>
      <label className="check-label ja-14 text-high" htmlFor={appearance.public_id}>
        <span className="checkbox-wrapper text-high ja-14">
          <input type="checkbox"
            id={appearance.public_id}
            name={`appearance[${appearance.public_id}]`}
            defaultChecked={this.props.userAppearances.map(appearance => appearance.public_id).includes(appearance.public_id)}
            onChange={this.changeAppearance.bind(this)}
            data-name={appearance.name}
          />
          <span className="checkbox-view"></span>
        </span>{appearance.name}
      </label>
    </div>)

    return (
      <div className="form-input-area">
        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">ニックネームを入力してください</label>
          <div className="input-wrap">
            <input id="nickname" className="form-input ja-14" maxLength="30" required="required" type="text" name="nickname" value={this.state.nickname} onChange={this.changeNickname.bind(this)} />
          </div>
        </div>


        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの年齢を教えて下さい</label>
          <div className="short-input-wrapper">
            <input id="age" className="form-input ja-14 short" min="18" required="required" type="number" name="age" value={this.state.age} onChange={this.changeAge.bind(this)} />
            <span className="ja-14">歳</span>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの身長を教えて下さい</label>
          <div className="short-input-wrapper">
            <input id="height" className="form-input ja-14 short" min="40" required="required" type="number" name="height" value={this.state.height} onChange={this.changeHeight.bind(this)} />
            <span className="ja-14">cm</span>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの体重を教えて下さい</label>
          <div className="short-input-wrapper">
            <input id="weight" className="form-input ja-14 short" min="30" required="required" type="number" name="weight" value={this.state.weight} onChange={this.changeWeight.bind(this)} />
            <span className="ja-14">kg</span>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">やり取りができるTwitter ID</label>
          <div className="input-wrap">
            <span className="twitter-at ja-14">@</span>
            <input id="twitter_id" className="form-input ja-14 twitter" required="required" type="text" name="twitter_id" value={this.state.twitterId} onChange={this.changeTwitterId.bind(this)} />
          </div>
          <div className="yellow-box">
            <p className="ja-12 text-high">マッチ後にお相手との連絡に使用するため正確なIDを入力してください。</p>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの活動範囲を教えて下さい</label>
          <p className="form-ex-txt-bottom text-medium ja-14">最大8個まで</p>
          <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openActiveAreaModal.bind(this)}>選択する</button>
          <p className="selected-content-txt ja-14 medium color-primary">{this.state.activeAreas.map(area => `${area.name}, `)}</p>

          <Modal1
            title="活動範囲"
            leftButtonLabel="選択をクリア"
            rightButtonLabel="決定"
            modalBody={activeAreas}
            openStatus={this.state.openActiveAreaModal}
            closeFunc={this.closeActiveAreaModal.bind(this)}
            leftButtonFunc={this.clearActiveArea.bind(this)}
            rightButtonFunc={this.closeActiveAreaModal.bind(this)}
          />
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたのポジションを教えて下さい</label>
          <div className="form-selctor">
            <button type="button" className="text-left select-box-open-button ja-14 text-high" onClick={this.togglePositionSelector.bind(this)}>{this.state.position.length > 0 ? this.state.position : "選択する"}</button>
            <div className={`form-selct-contents shadow-8dp ${this.state.openPosition ? 'open' : ''}`}>
              {this.props.positions.map((pos, key) => <label className="label" key={key}>
                <input
                  type="radio"
                  name="position"
                  value={pos[1]}
                  onChange={this.changePosition.bind(this)}
                  defaultChecked={pos[1] === this.props.position}
                />
                <p className="label-txt ja-14">{pos[0]}</p>
              </label>)}
            </div>
          </div>
        </div>
        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの体型を教えて下さい</label>
          <div className="form-selctor">
            <button type="button" className="text-left select-box-open-button ja-14 text-high" onClick={this.toggleBodyShapeSelector.bind(this)}>{this.state.bodyShape.length > 0 ? this.state.bodyShape : "選択する"}</button>
            <div className={`form-selct-contents shadow-8dp ${this.state.openBodyShape ? 'open' : ''}`}>
              {this.props.bodyShapes.map((bs, key) => <label className="label" key={key}><input type="radio" name="body_shape" value={bs[1]} onChange={this.changeBodyShape.bind(this)} defaultChecked={bs[1] === this.props.bodyShape} /><p className="label-txt ja-14">{bs[0]}</p></label>)}
            </div>
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">あなたの見た目の特徴を教えて下さい</label>
            <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openAppearancesModal.bind(this)}>選択する</button>
            <p className="selected-content-txt ja-14 medium color-primary">{this.state.userAppearances.map(appearance => `${appearance.name}, `)}</p>

            <Modal1
              title="あなたの見た目の特徴"
              leftButtonLabel="選択をクリア"
              rightButtonLabel="決定"
              modalBody={appearances}
              openStatus={this.state.openAppearancesModal}
              closeFunc={this.closeAppearancesModal.bind(this)}
              leftButtonFunc={this.clearAppearance.bind(this)}
              rightButtonFunc={this.closeAppearancesModal.bind(this)}
            />
          </div>

          <div className="form-checkbox">
            <label className="form-ttl ja-14 medium">都合のつきやすいタイミングを教えて下さい</label>
            <div className="checkbox-area mt8">{timings}</div>
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium optional-label">プロフ画像</label>
            <div className="yellow-box">
              <p className="ja-12 text-high">局部の画像含む性的な画像はお控えください。</p>
            </div>
            <div className="image-area">
              <label
                className="image-upload-label"
                htmlFor="user_image"
                style={{ backgroundImage: `url(${this.state.imageUri ? this.state.imageUri : 'https://fety-assets.s3.ap-northeast-1.amazonaws.com/img/icon/image_upload_bg.svg'}` }}
              >
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  name="user_image"
                  id="user_image"
                  className="input-file"
                  onChange={this.changeImage.bind(this)}
                />
                <input
                  type="hidden"
                  value={this.state.base64Image}
                  name="image_base64"
                  id="image_base64"
                />
              </label>
              { this.state.imageUri ? <label htmlFor="user_image" className="edit-message ja-14 medium color-primary">編集する</label> : ''}
            </div>
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium optional-label">プロフィール、自己PRなど自由に記入</label>
            <textarea className="form-textarea ja-14" name="comment" id="comment" onChange={this.changeComment.bind(this)} value={this.state.comment}></textarea>
            <p className="en-12">{obtainUnicode.obtainUnicode(this.state.comment).length}/200</p>
          </div>
        </div>
      </div>
    );
  }
}
SettingsProfileShow.propTypes = {
  nickname: PropTypes.string,
  age: PropTypes.number,
  height: PropTypes.string,
  weight: PropTypes.string,
  twitterId: PropTypes.string,
  comment: PropTypes.string,
  position: PropTypes.string,
  bodyShape: PropTypes.string,
  imageUri: PropTypes.string,
  userTimings: PropTypes.array,
  userActiveAreas: PropTypes.array,
  userAppearances: PropTypes.array,

  positions: PropTypes.array,
  bodyShapes: PropTypes.array,
  timings: PropTypes.array,
  activeAreaCategories: PropTypes.array,
  appearances: PropTypes.array,

};
export default SettingsProfileShow
