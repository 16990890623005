import React from "react"
import PropTypes from "prop-types"
class UsersRegistrationsNew extends React.Component {
  state = {
    mail: this.props.mail,
    tel: this.props.tel,
    password: this.props.password,
    passwordIsShow: false,
    policyCheck: this.props.policyCheck,
    passwordError: false,
    emailError: false,
    formValid: false
  }
  showPassword() {
    this.setState({ passwordIsShow: !this.state.passwordIsShow })
  }

  changeMail(e) {
    const target = e.currentTarget
    this.setState(({ mail: target.value }), () => {
      this.unLockCheck()
    })
  }

  telSanitize(tel) {
    const replaceTel = tel.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    }).replace(/[^0-9]/g, '')
    return replaceTel
  }
  changeTel(e) {
    const target = e.currentTarget
    const tel = this.telSanitize(target.value)

    this.setState(({ tel: tel }), () => {
      this.unLockCheck()
    })
  }
  changePassword(e) {
    const target = e.currentTarget
    this.setState(({ password: target.value }), () => {
      this.unLockCheck()
    })
  }
  agree() {
    this.setState(({ policyCheck: !this.state.policyCheck }), () => {
      this.unLockCheck()
    })
  }

  emailValid() {
    if (this.state.mail.match(/.+@.+\..+/)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }
  passwordValid() {
    const password = this.state.password
    const rall = /^(?=.*?[a-z A-Z])(?=.*?[0-9])/
    if (rall.test(password) && password.length >= 8) {
      this.setState({ passwordError: false })
      return true
    } else {
      this.setState({ passwordError: true })
      return false
    }
  }
  unLockCheck() {
    if (this.emailValid() && this.passwordValid() && this.state.tel.length >= 9 && this.state.policyCheck) {
      this.setState({ formValid: true })
    } else {
      this.setState({ formValid: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-area">
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">メールアドレス</label>
            <input autoFocus="autofocus" autoComplete="email" className="form-input ja-14" type="email" value={this.state.email} name="user[email]" id="user_email" onChange={this.changeMail.bind(this)} />
            {
              this.state.emailError ?
                <p className="form-ex-txt-bottom color-error ja-12">メールアドレスの形式が不正です</p> : ''
            }
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">パスワード</label>
            <div className="input-wrap">
              <input autoComplete="new-password" className="form-input ja-14 password" type={this.state.passwordIsShow ? 'text' : 'password'} name="user[password]" id="user_password" onChange={this.changePassword.bind(this)} />
              <span className={`password-eye ${this.state.passwordIsShow ? 'open' : 'closed'}`} onClick={this.showPassword.bind(this)}></span>
            </div>
            {
              !this.state.passwordError ?
                <p className="form-ex-txt-bottom text-medium ja-12">8文字以上・英数字</p> :
                <p className="form-ex-txt-bottom color-error ja-12">8文字以上・英数字で入力してください</p>
            }
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">電話番号</label>
            <p className="form-ex-txt text-medium ja-12">本人確認用に使用します</p>
            <input autoFocus="autofocus" autoComplete="tel" className="form-input ja-14" type="tel" value={this.state.tel} name="user[tel]" id="user_tel" onChange={this.changeTel.bind(this)} />
            <p className="form-ex-txt-bottom text-medium ja-12">ハイフンなしで入力</p>
          </div>
        </div>
        <div className="form-checkbox mt24">
          <div className="check-label ja-12" onClick={this.agree.bind(this)}>
            <span className="checkbox-wrapper">
              <input type="checkbox" id="agree" checked={this.state.policyCheck} readOnly />
              <span className="checkbox-view"></span>
            </span>18歳以上であること、利用規約・プライバシーポリシーに同意します。
          </div>
        </div>
        <div className="form-button-area mt24">
          <button name="button" type="submit" className="default-btn hov-op_8 ja-14 medium" disabled={!this.state.formValid}>次へ</button>
          <a href="/login" className="mt16 hov-op_8 button-area-link color-primary text-center ja-14 medium">アカウントをお持ちの方はこちら</a>
        </div>
      </React.Fragment>
    );
  }
}

UsersRegistrationsNew.propTypes = {
  mail: PropTypes.string,
  tel: PropTypes.string,
  policyCheck: PropTypes.bool,
};
export default UsersRegistrationsNew
