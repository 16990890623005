import React from "react"

class UsersRegistrationsNew extends React.Component {
  state = {
    login: '',
    password: '',
    formValid: false,
    passwordIsShow: false,
    error: '',
    telValid: false,
    emailValid: false,
  }
  showPassword() {
    this.setState({ passwordIsShow: !this.state.passwordIsShow })
  }

  changeLogin(e) {
    const target = e.currentTarget
    this.setState(({ login: target.value }), () => {
      this.unLockCheck()
    })
  }

  changePassword(e) {
    const target = e.currentTarget
    this.setState(({ password: target.value }), () => {
      this.unLockCheck()
    })
  }

  async emailValid(mail) {
    let check = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.setState({
      emailValid: check.test(mail),
      error: ''
    })
  }

  async telValid(tel) {
    const telNum = tel.replace('-', '')
    const regexp = /^0\d{9,10}$/;

    this.setState({
      telValid: regexp.test(telNum),
      error: ''
    })
  }

  async unLockCheck() {
    const login = this.state.login
    const password = this.state.password

    if (login && password && login.length >= 10 && password.length >= 8) {
      await this.emailValid(login)
      await this.telValid(login)

      if (this.state.telValid || this.state.emailValid) {
        this.setState({
          formValid: true,
          error: ''
        })
      } else {
        let errorMessage = ''
        if (!this.state.telValid && !this.state.emailValid) {
          errorMessage = 'メールアドレスおよび電話番号の形式が不正です'
        } else if (!this.state.telValid) {
          errorMessage = '電話番号の形式が不正です'
        } else if (!this.state.emailValid) {
          errorMessage = 'メールアドレスの形式が不正です'
        }

        this.setState({ error:  errorMessage})
      }
    } else {
      this.setState({ formValid: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-area">
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">メールアドレスまたは電話番号</label>
            <input autoFocus="autofocus" className="form-input ja-14" type="text" value={this.state.login} name="user[login]" id="user_login" onChange={this.changeLogin.bind(this)}  />
            {this.state.telValid || this.state.emailValid ? '' : <p className="form-ex-txt-bottom color-error ja-12">{this.state.error}</p>}
          </div>

          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">パスワード</label>
            <div className="input-wrap">
              <input autoComplete="current-password" className="form-input ja-14 password" value={this.state.password} type={this.state.passwordIsShow ? 'text' : 'password'} name="user[password]" id="user_password" onChange={this.changePassword.bind(this)}  />
              <span className={`password-eye ${this.state.passwordIsShow ? 'open' : 'closed'}`} onClick={this.showPassword.bind(this)}></span>
            </div>
          </div>
        </div>

        <div className="form-button-area mt28">
          <button name="button" type="submit" className="default-btn hov-op_8 ja-14 medium" disabled={!this.state.formValid}>ログイン</button>
          <a href="/users/password/new/" className="mt16 hov-op_8 button-area-link color-primary text-center ja-14 medium">パスワードをお忘れの方はこちら</a>
        </div>
      </React.Fragment>
    );
  }
}
export default UsersRegistrationsNew
