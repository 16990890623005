import React from "react"
import PropTypes from "prop-types"

class SharedModal2 extends React.Component {
  render() {
    const openStyle = this.props.openStatus ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }

    return (
      <div className="confirm-modal" style={openStyle}>
        <div className="overlay" onClick={this.props.closeFunc.bind(this)}></div>
        <div className="modal">
          <h2 className="ja-16 bold text-high" dangerouslySetInnerHTML={{ __html: this.props.title }}></h2>
          {this.props.text ? <p className="mt8 ja-14 text-high" dangerouslySetInnerHTML={{ __html: this.props.text }}></p> : ''}

          <div className="confirm-buttons">
            <button className="default-btn ja-14 medium hov-op_8" onClick={this.props.upButtonFunc.bind(this)}>{this.props.upButtonLabel}</button>
            <button className="white-btn ja-14 medium hov-op_8" onClick={this.props.bottomButtonFunc.bind(this)}>{this.props.bottomButtonLabel}</button>
          </div>
        </div>
      </div>
    );
  }
}
SharedModal2.propTypes = {
  openStatus: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  upButtonLabel: PropTypes.string,
  bottomButtonLabel: PropTypes.string,
  upButtonFunc: PropTypes.func,
  bottomButtonFunc: PropTypes.func,
  closeFunc: PropTypes.func,
};
export default SharedModal2
