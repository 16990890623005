import React from "react"
import PropTypes from "prop-types"

import Modal1 from "../../shared/modal1"

class SettingsFetishShow extends React.Component {
  state = {
    sm: this.props.sm,
    allowableAge: this.props.dataAllowableAges.map(aa => aa[1] === this.props.allowableAge ? aa[0] : null ).filter(Boolean),
    favBodyShape: this.props.dataFavBodyShapes.map(fbs => fbs[1] === this.props.favBodyShape ? fbs[0] : null).filter(Boolean),
    userLikeAppearances: this.props.userLikeAppearances,
    userLikePlays: this.props.userLikePlays,
    userInterestPlays: this.props.userInterestPlays,
    userAppealPoints: this.props.userAppealPoints,

    openAllowableAge: false,
    openFavBodyShape: false,

    openLikeAppearancesModal: false,
    openLikePlaysModal: false,
    openInterestPlaysModal: false,
    openAppealPointsModal: false,
  }


  unLockCheck() {
    if (this.state.sm && this.state.allowableAge && this.state.favBodyShape.length && this.state.userLikeAppearances.length > 0 && this.state.userLikePlays.length > 0 && this.state.userInterestPlays.length > 0 && this.state.userAppealPoints.length > 0) {
      const submitButton = document.querySelector('#submitButton')
      submitButton.disabled = false
      submitButton.textContent = '更新する'
    } else {
      const submitButton = document.querySelector('#submitButton')
      submitButton.disabled = true
      submitButton.textContent = 'すべて入力してください'
    }
  }

  changeSm(e) {
    const target = e.currentTarget
    this.setState(({ sm: target.value }), () => {
      this.unLockCheck()
    })
  }

  changeAllowableAge(e) {
    const target = e.currentTarget
    const allowableAge = this.props.dataAllowableAges.map(aa => aa[1] === target.value ? aa[0] : null ).filter(Boolean)
    this.setState(({ allowableAge: allowableAge }), () => {
      this.closeAllowableAgeSelector()
      this.unLockCheck()
    })
  }

  changeFavBodyShape(e) {
    const target = e.currentTarget
    const favBodyShape = this.props.dataFavBodyShapes.map(fbs => fbs[1] === target.value ? fbs[0] : null ).filter(Boolean)
    this.setState(({ favBodyShape: favBodyShape }), () => {
      this.closeFavBodyShapeSelector()
      this.unLockCheck()
    })
  }

  // Appearance
  changeLikeAppearance(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userLikeAppearances
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 3) {
      target.checked = false
      alert('相手の好きな見た目は3個以内に設定してください')
    } else {
      this.setState(({ userLikeAppearances: newArray }), () => {
        this.unLockCheck()
      })
    }
  }

  clearLikeAppearance(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ userLikeAppearances: [] }), () => {
      this.unLockCheck()
    })
  }

  // LikePlays
  changeLikePlay(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userLikePlays
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 5) {
      target.checked = false
      alert('好きなプレイは5個以内に設定してください')
    } else {
      this.setState(({ userLikePlays: newArray }), () => {
        this.unLockCheck()
      })
    }
  }

  clearLikePlay(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ userLikePlays: [] }), () => {
      this.unLockCheck()
    })
  }

  // InterestPlays
  changeInterestPlay(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userInterestPlays
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 5) {
      target.checked = false
      alert('興味のあるプレイは5個以内に設定してください')
    } else {
      this.setState(({ userInterestPlays: newArray }), () => {
        this.unLockCheck()
      })
    }
  }

  clearInterestPlay(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ userInterestPlays: [] }), () => {
      this.unLockCheck()
    })
  }

  // AppealPoints
  changeAppealPoint(e) {
    const target = e.currentTarget
    const id = target.id
    const name = target.dataset.name
    const checked = target.checked
    const currentArray = this.state.userAppealPoints
    let newArray = []
    if (checked) {
      newArray = currentArray.concat({public_id: id, name: name})
    } else {
      newArray = currentArray.map(item => {
        if (item.public_id === id) return null
        return item
      }).filter(Boolean)
    }

    if (newArray.length > 3) {
      target.checked = false
      alert('アピールポイントは3個以内に設定してください')
    } else {
      this.setState(({ userAppealPoints: newArray }), () => {
        this.unLockCheck()
      })
    }
  }

  clearAppealPoint(e) {
    const target = e.currentTarget
    const modal = target.parentNode.parentNode
    const checkContents = modal.querySelectorAll('input[type="checkbox"]:checked')
    checkContents.forEach(check => { check.checked = false })
    this.setState(({ userAppealPoints: [] }), () => {
      this.unLockCheck()
    })
  }

  // Selector関係
  toggleAllowableAgeSelector() {
    this.setState({ openAllowableAge: !this.state.openAllowableAge })
    this.closeFavBodyShapeSelector()
  }
  openAllowableAgeSelector() {
    this.setState({ openAllowableAge: true })
  }
  closeAllowableAgeSelector() {
    this.setState({ openAllowableAge: false })
  }

  toggleFavBodyShapeSelector() {
    this.setState({ openFavBodyShape: !this.state.openFavBodyShape })
    this.closeAllowableAgeSelector()
  }
  openFavBodyShapeSelector() {
    this.setState({ openFavBodyShape: true })
  }
  closeFavBodyShapeSelector() {
    this.setState({ openFavBodyShape: false })
  }

  // Modal関係
  openLikeAppearancesModal() {
    this.setState({ openLikeAppearancesModal: true })
    this.closeLikePlaysModal()
    this.closeInterestPlaysModal()
    this.closeAppealPointsModal()
  }
  closeLikeAppearancesModal() {
    this.setState({ openLikeAppearancesModal: false })
  }

  openLikePlaysModal() {
    this.setState({ openLikePlaysModal: true })
    this.closeLikeAppearancesModal()
    this.closeInterestPlaysModal()
    this.closeAppealPointsModal()
  }
  closeLikePlaysModal() {
    this.setState({ openLikePlaysModal: false })
  }

  openInterestPlaysModal() {
    this.setState({ openInterestPlaysModal: true })
    this.closeLikePlaysModal()
    this.closeLikeAppearancesModal()
    this.closeAppealPointsModal()()
  }
  closeInterestPlaysModal() {
    this.setState({ openInterestPlaysModal: false })
  }

  openAppealPointsModal() {
    this.setState({ openAppealPointsModal: true })
    this.closeLikePlaysModal()
    this.closeInterestPlaysModal()
    this.closeLikeAppearancesModal()
  }
  closeAppealPointsModal() {
    this.setState({ openAppealPointsModal: false })
  }


  componentDidMount() {
    this.unLockCheck()
  }

  render() {
    const likeAppearances = this.props.dataAppearances.map((appearance, key) => <div className="checks mt8" key={key}>
      <label className="check-label ja-14 text-high" htmlFor={appearance.public_id}>
        <span className="checkbox-wrapper text-high ja-14">
          <input type="checkbox"
            id={appearance.public_id}
            name={`appearance[${appearance.public_id}]`}
            defaultChecked={this.props.userLikeAppearances.map(appearance => appearance.public_id).includes(appearance.public_id)}
            onChange={this.changeLikeAppearance.bind(this)}
            data-name={appearance.name}
          />
          <span className="checkbox-view"></span>
        </span>{appearance.name}
      </label>
    </div>)

    const likePlays = this.props.dataPlays.map((play, key) => <div className="checks mt8" key={key}>
      <label className="check-label ja-14 text-high" htmlFor={play.public_id}>
        <span className="checkbox-wrapper text-high ja-14">
          <input type="checkbox"
            id={play.public_id}
            name={`like_play[${play.public_id}]`}
            defaultChecked={this.props.userLikePlays.map(play => play.public_id).includes(play.public_id)}
            onChange={this.changeLikePlay.bind(this)}
            data-name={play.name}
          />
          <span className="checkbox-view"></span>
        </span>{play.name}
      </label>
    </div>)

    const interestPlays = this.props.dataPlays.map((play, key) => <div className="checks mt8" key={key}>
      <label className="check-label ja-14 text-high" htmlFor={`interest_${play.public_id}`}>
        <span className="checkbox-wrapper text-high ja-14">
          <input type="checkbox"
            id={`interest_${play.public_id}`}
            name={`interest_play[${play.public_id}]`}
            defaultChecked={this.props.userInterestPlays.map(play => play.public_id).includes(play.public_id)}
            onChange={this.changeInterestPlay.bind(this)}
            data-name={play.name}
          />
          <span className="checkbox-view"></span>
        </span>{play.name}
      </label>
    </div>)

    const appealPoints = this.props.dataAppealPoints.map((ap, key) => <div className="checks mt8" key={key}>
      <label className="check-label ja-14 text-high" htmlFor={ap.public_id}>
        <span className="checkbox-wrapper text-high ja-14">
          <input type="checkbox"
            id={ap.public_id}
            name={`appeal_point[${ap.public_id}]`}
            defaultChecked={this.props.userAppealPoints.map(ap => ap.public_id).includes(ap.public_id)}
            onChange={this.changeAppealPoint.bind(this)}
            data-name={ap.name}
          />
          <span className="checkbox-view"></span>
        </span>{ap.name}
      </label>
    </div>)

    return (
      <div className="form-input-area">
        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">Sですか？Mですか？</label>
          <div className="radio-area">
            {this.props.dataSms.map((sm, key) =>
              <label className="form-radio" htmlFor={sm[1]} key={key}>
                <input
                  type="radio"
                  id={sm[1]}
                  name="sm"
                  value={sm[1]}
                  defaultChecked={this.props.sm === sm[1]}
                  onChange={this.changeSm.bind(this)}
                />
                <span className="radio-circle"></span>
                <p className="label-txt ja-14">{sm[0]}</p>
              </label>
            )}
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">歳上は何歳まで許容できますか？</label>
          <div className="form-selctor">
            <button type="button" className="text-left select-box-open-button ja-14 text-high" onClick={this.toggleAllowableAgeSelector.bind(this)}>{this.state.allowableAge.length > 0 ? this.state.allowableAge : "選択する"}</button>

            <div className={`form-selct-contents shadow-8dp ${this.state.openAllowableAge ? 'open' : ''}`}>

              {this.props.dataAllowableAges.map((aa, key) => <label className="label" key={key}>
                <input
                  type="radio"
                  name="allowable_age"
                  value={aa[1]}
                  onChange={this.changeAllowableAge.bind(this)}
                  defaultChecked={aa[1] === this.props.allowableAge} />
                <p className="label-txt ja-14">{aa[0]}</p>
              </label>)}
            </div>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">相手の好きな体型について教えて下さい</label>
          <div className="form-selctor">
            <button type="button" className="text-left select-box-open-button ja-14 text-high" onClick={this.toggleFavBodyShapeSelector.bind(this)}>{this.state.favBodyShape.length > 0 ? this.state.favBodyShape : "選択する"}</button>

            <div className={`form-selct-contents shadow-8dp ${this.state.openFavBodyShape ? 'open' : ''}`}>
              {this.props.dataFavBodyShapes.map((fbs, key) => <label className="label" key={key}>
                <input
                  type="radio"
                  name="fav_body_shape"
                  value={fbs[1]}
                  onChange={this.changeFavBodyShape.bind(this)}
                  defaultChecked={fbs[1] === this.props.favBodyShape} />
                <p className="label-txt ja-14">{fbs[0]}</p>
              </label>)}
            </div>
          </div>
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">相手の好きな見た目を教えて下さい</label>

          <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openLikeAppearancesModal.bind(this)}>選択する</button>
          <p className="selected-content-txt ja-14 medium color-primary">{this.state.userLikeAppearances.map(appearance => `${appearance.name}, `)}</p>

          <Modal1
            title="相手の好きな見た目"
            leftButtonLabel="選択をクリア"
            rightButtonLabel="決定"
            modalBody={likeAppearances}
            openStatus={this.state.openLikeAppearancesModal}
            closeFunc={this.closeLikeAppearancesModal.bind(this)}
            leftButtonFunc={this.clearLikeAppearance.bind(this)}
            rightButtonFunc={this.closeLikeAppearancesModal.bind(this)}
          />
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの好きなプレイを教えて下さい</label>

          <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openLikePlaysModal.bind(this)}>選択する</button>
          <p className="selected-content-txt ja-14 medium color-primary">{this.state.userLikePlays.map(play => `${play.name}, `)}</p>

          <Modal1
            title="あなたの好きなプレイ"
            leftButtonLabel="選択をクリア"
            rightButtonLabel="決定"
            modalBody={likePlays}
            openStatus={this.state.openLikePlaysModal}
            closeFunc={this.closeLikePlaysModal.bind(this)}
            leftButtonFunc={this.clearLikePlay.bind(this)}
            rightButtonFunc={this.closeLikePlaysModal.bind(this)}
          />
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたの興味のあるプレイを教えて下さい</label>

          <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openInterestPlaysModal.bind(this)}>選択する</button>
          <p className="selected-content-txt ja-14 medium color-primary">{this.state.userInterestPlays.map(play => `${play.name}, `)}</p>

          <Modal1
            title="あなたの興味のあるプレイ"
            leftButtonLabel="選択をクリア"
            rightButtonLabel="決定"
            modalBody={interestPlays}
            openStatus={this.state.openInterestPlaysModal}
            closeFunc={this.closeInterestPlaysModal.bind(this)}
            leftButtonFunc={this.clearInterestPlay.bind(this)}
            rightButtonFunc={this.closeInterestPlaysModal.bind(this)}
          />
        </div>

        <div className="form-input-box">
          <label className="form-ttl ja-14 medium">あなたのアピールポイントを教えて下さい</label>

          <button type="button" className="select-modal-open-button ja-14 color-primary medium" onClick={this.openAppealPointsModal.bind(this)}>選択する</button>
          <p className="selected-content-txt ja-14 medium color-primary">{this.state.userAppealPoints.map(play => `${play.name}, `)}</p>

          <Modal1
            title="あなたのアピールポイント"
            leftButtonLabel="選択をクリア"
            rightButtonLabel="決定"
            modalBody={appealPoints}
            openStatus={this.state.openAppealPointsModal}
            closeFunc={this.closeAppealPointsModal.bind(this)}
            leftButtonFunc={this.clearAppealPoint.bind(this)}
            rightButtonFunc={this.closeAppealPointsModal.bind(this)}
          />
        </div>
      </div>
    );
  }
}
SettingsFetishShow.propTypes = {
  sm: PropTypes.string,
  allowableAge: PropTypes.string,
  favBodyShape: PropTypes.string,
  userLikeAppearances: PropTypes.array,
  userLikePlays: PropTypes.array,
  userInterestPlays: PropTypes.array,
  userAppealPoints: PropTypes.array,

  dataSms: PropTypes.array,
  dataAllowableAges: PropTypes.array,
  dataFavBodyShapes: PropTypes.array,
  dataAppearances: PropTypes.array,
  dataPlays: PropTypes.array,
  dataAppealPoints: PropTypes.array,
};
export default SettingsFetishShow
