import React from "react"
import PropTypes from "prop-types"

class MypageSettingTelSetting extends React.Component {
  state = {
    tel: '',
  }

  telSanitize(tel) {
    const replaceTel = tel.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    }).replace(/[^0-9]/g, '')
    return replaceTel
  }

  changeTel(e) {
    const target = e.currentTarget
    const tel = this.telSanitize(target.value)

    this.setState({ tel: tel })
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-area">
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">現在の電話番号</label>
            <p className="ja-14 medium">{this.props.currentTel}</p>
          </div>
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">新しい電話番号</label>
            <input autoFocus="autofocus" autoComplete="tel" className="form-input ja-14" type="tel" value={this.state.tel} name="tel" id="tel" required={true} onChange={this.changeTel.bind(this)} />
            <p className="form-ex-txt-bottom text-medium ja-12">ハイフンなしで入力</p>
          </div>
        </div>
        <div className="form-button-area mt34">
          <button className="default-btn hov-op_8 ja-14 medium" disabled={this.state.tel.length < 9}>{this.state.tel.length < 9 ? '電話番号を入力してください' : '電話番号の認証へ'}</button>
        </div>
      </React.Fragment>
    );
  }
}

MypageSettingTelSetting.propTypes = {
  currentTel: PropTypes.string,
};
export default MypageSettingTelSetting
