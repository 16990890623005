import React from "react"

class UsersPassewordsNew extends React.Component {
  state = {
    mail: '',
    emailError: false,
  }

  changeMail(e) {
    const target = e.currentTarget
    this.setState(({ mail: target.value }), () => {
      this.emailValid()
    })
  }

  emailValid() {
    if (this.state.mail.match(/.+@.+\..+/)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-area">
          <div className="form-input-box">
            <label className="form-ttl ja-14 medium">登録済みのメールアドレス</label>
            <input autoFocus="autofocus" autoComplete="email" className="form-input ja-14" type="email" value={this.state.mail} name="user[email]" required={true} id="email" onChange={this.changeMail.bind(this)} />
          </div>
        </div>
        <div className="form-button-area mt34">
          <button className="default-btn hov-op_8 ja-14 medium" disabled={!this.state.mail.length > 0 || this.state.emailError}>{this.state.mail.length > 0 && !this.state.emailError ? 'パスワードをリセット' : 'メールアドレスを入力してください'}</button>
        </div>
      </React.Fragment>
    );
  }
}

export default UsersPassewordsNew
