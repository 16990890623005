import React from "react"
import Modal2 from "../../shared/modal2"

class MypageSettingLogout extends React.Component {
  state = {
    openModal: false,
  }

  openModal() {
    this.setState({ openModal: true })
  }

  closeModal() {
    this.setState({ openModal: false })
  }

  logout() {
    window.location.href = '/logout'
  }


  render() {
    return (
      <React.Fragment>
        <button className="link ja-14 medium text-high" style={{width: "100%"}} onClick={this.openModal.bind(this)}>ログアウト</button>
        <Modal2
          openStatus={this.state.openModal}
          title="ログアウトしますか？"
          text=""
          upButtonLabel="ログアウト"
          bottomButtonLabel="キャンセル"
          upButtonFunc={this.logout.bind(this)}
          bottomButtonFunc={this.closeModal.bind(this)}
          closeFunc={this.closeModal.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default MypageSettingLogout
