import React from "react"
import PropTypes from "prop-types"
import Modal3 from "../../shared/modal3"

class UsersDetailTwitterButton extends React.Component {
  state = {
    openModal: false,
  }

  openModal() {
    if (!this.fetchCookie()) {
      this.setState({ openModal: true })
    } else {
      this.openTwitter()
    }
  }

  closeModal() {
    this.setState({ openModal: false })
  }

  openTwitter() {
    window.location.href = `https://twitter.com/${this.props.twitterId}`
  }

  fetchCookie() {
    const cookies = document.cookie
    const cookiesArray = cookies.split(';')
    let twCheck = 'off'

    for(const c of cookiesArray){
      const cArray = c.split('=');
      if (cArray[0] == 'tw_check') twCheck = cArray[1]
    }

    return twCheck === 'on'
  }

  render() {
    return (
      <React.Fragment>
        <button className="twitter-btn hov-op_8 ja-14 medium" style={{width: "100%"}} onClick={this.openModal.bind(this)}><img src="https://fety-assets.s3.ap-northeast-1.amazonaws.com/img/icon/twitter.svg" alt="Twitterアイコン" />Twitterでメッセージする</button>
        <Modal3
          openStatus={this.state.openModal}
          title="アカウントの鍵の解除と<br />DM開放は行いましたか？"
          text="お相手のTwitterページに移動します。<br />お相手とやり取りする上で鍵の解除とDM開放をお願いします。"
          upButtonLabel="確認しました"
          bottomButtonLabel="キャンセル"
          upButtonFunc={this.openTwitter.bind(this)}
          bottomButtonFunc={this.closeModal.bind(this)}
          closeFunc={this.closeModal.bind(this)}
        />
      </React.Fragment>
    );
  }
}
UsersDetailTwitterButton.propTypes = {
  twitterId: PropTypes.string,
}

export default UsersDetailTwitterButton
