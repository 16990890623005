import React from "react"
import PropTypes from "prop-types"

class SharedModal1 extends React.Component {
  render() {
    const openStyle = this.props.openStatus ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }

    return (
      <div className="modal1" style={openStyle}>
        <div className="modal-wrapper" onClick={this.props.closeFunc.bind(this)}></div>
        <div className="modal-content shadow-8dp">
          <div className="modal-top">
            <h2 className="ttl ja-16 medium">{ this.props.title }</h2>
            <span className="close" onClick={this.props.closeFunc.bind(this)}></span>
          </div>
          <div className="modal-body">
            {this.props.modalBody}
          </div>
          <div className="modal-bottom">
            <button className="btn button-left ja-14 medium color-primary" type="button" onClick={this.props.leftButtonFunc.bind(this)}>{ this.props.leftButtonLabel }</button>
            <button className="btn button-right ja-14 medium color-white background-primary hov-op_8" type="button" onClick={this.props.rightButtonFunc.bind(this)}>{ this.props.rightButtonLabel }</button>
          </div>
        </div>
      </div>
    );
  }
}
SharedModal1.propTypes = {
  title: PropTypes.string,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,
  modalBody: PropTypes.array,
  openStatus: PropTypes.bool,
  closeFunc: PropTypes.func,
  leftButtonFunc: PropTypes.func,
  rightButtonFunc: PropTypes.func,
};
export default SharedModal1
