import React from "react"
import PropTypes from "prop-types"

class PaymentWithdrawModal extends React.Component {
  state = {
    openStatus: false,
  }
  closeModal() {
    this.setState({ openStatus: false })
  }

  openModal() {
    if (this.props.isFinish) return

    this.setState({ openStatus: true })
  }

  render() {
    const openStyle = this.state.openStatus ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }

    const modal = <div className="confirm-modal error" style={openStyle}>
      <div className="overlay" onClick={this.closeModal.bind(this)}></div>
      <div className="modal">
        <h2 className="ja-16 bold text-high">プランを解約しますか？</h2>
        <p className="mt8 ja-14 text-high">解約した場合でも有効期限内はご利用頂けます。</p>

        <div className="confirm-buttons">
          <a href="/payments/cancel" data-method="delete" className="default-btn ja-14 medium hov-op_8">解約する</a>
          <button className="white-btn ja-14 medium hov-op_8" onClick={this.closeModal.bind(this)}>戻る</button>
        </div>
      </div>
    </div>


    return <React.Fragment>
      <button className="cancel-button ja-14 medium" onClick={this.openModal.bind(this)} disabled={this.props.isFinish}>プランを解約する</button>
      {this.props.isFinish ? '' : modal}
    </React.Fragment>;
  }
}
PaymentWithdrawModal.propTypes = {
  isFinish: PropTypes.bool,
}
export default PaymentWithdrawModal
